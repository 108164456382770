export const LoadingScreen = ({ loadingText }: { loadingText?: string }) => {
  return (
    <div className="loading-screen">
      <svg
        className="cross"
        width="32"
        height="33"
        viewBox="0 0 32 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M31.1815 0.573735C31.1815 2.09651 31.2104 3.53448 31.1562 4.96877C31.1454 5.26005 30.8564 5.58083 30.6253 5.8168C27.36 9.14995 24.0802 12.4757 20.8041 15.7941C20.544 16.0559 20.2551 16.2882 19.9336 16.5795C20.1431 16.8081 20.2767 16.9629 20.4212 17.1104C23.9249 20.6759 27.4286 24.2413 30.9287 27.8104C31.0407 27.9247 31.1671 28.0943 31.1707 28.2381C31.1888 29.7093 31.1815 31.1804 31.1815 32.7143C29.6861 32.7143 28.2377 32.7327 26.7929 32.6958C26.5906 32.6921 26.3703 32.4488 26.2005 32.2755C22.7149 28.7469 19.2365 25.211 15.7545 21.6787C15.6461 21.5681 15.5233 21.4796 15.3463 21.3248C15.0176 21.6751 14.707 22.0143 14.3819 22.3461C11.066 25.7124 7.75014 29.0825 4.42705 32.4414C4.29341 32.5778 4.07668 32.7069 3.89608 32.7069C2.63909 32.729 1.38209 32.718 0.0673046 32.718C0.0528564 32.5299 0.0311841 32.3603 0.0311841 32.1944C0.0311841 30.8154 0.0131238 29.4364 0.0456323 28.0611C0.0528564 27.7883 0.186502 27.4491 0.374329 27.2537C3.67575 23.8726 6.99522 20.5062 10.3183 17.1473C10.4664 16.9961 10.6867 16.9224 10.9288 16.7786C10.3725 16.1813 9.986 15.7462 9.57784 15.3332C6.62318 12.3504 3.67213 9.3638 0.695802 6.40306C0.219011 5.93111 -0.0266088 5.47022 0.00228766 4.76598C0.0636926 3.39437 0.0203479 2.01908 0.0203479 0.577422C1.37487 0.577422 2.68604 0.566361 3.99361 0.59217C4.14893 0.59217 4.3223 0.769152 4.45234 0.901888C7.77181 4.29403 11.0841 7.68986 14.3963 11.0894C15.3174 12.037 15.3174 12.0443 16.2312 11.1152C19.5724 7.71566 22.9063 4.31246 26.2619 0.927698C26.4534 0.735968 26.7821 0.592171 27.053 0.584797C28.4039 0.551613 29.7584 0.570048 31.1779 0.570048L31.1815 0.573735Z" />
      </svg>
      <svg
        className="plus"
        width="45"
        height="46"
        viewBox="0 0 45 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M25.7323 25.8941C25.7323 27.2583 25.7323 28.5562 25.7323 29.8541C25.7323 33.696 25.725 37.5343 25.7431 41.3763C25.7431 42.0142 25.5589 42.5119 25.1182 42.9581C24.2694 43.8172 23.4458 44.7021 22.5681 45.6275C21.5206 44.5583 20.5345 43.5959 19.6098 42.5746C19.3931 42.3349 19.2992 41.8962 19.2956 41.5459C19.2811 36.642 19.2884 31.7382 19.2956 26.8343C19.2956 25.8978 19.2956 25.8683 18.3637 25.872C13.8486 25.8794 9.33716 25.8572 4.82209 25.9273C3.60122 25.9457 2.67292 25.6508 1.91439 24.6479C1.37619 23.9399 0.700738 23.339 0 22.6015C0.769368 21.8457 1.56402 21.1746 2.2178 20.3819C2.82463 19.6482 3.5073 19.3974 4.42477 19.4011C8.97595 19.4269 13.5308 19.4011 18.0819 19.3974C18.4648 19.3974 18.8477 19.4417 19.2956 19.4675C19.2956 19.2057 19.2956 18.9771 19.2956 18.7485C19.2956 13.6087 19.2884 8.46884 19.2956 3.32901C19.2956 3.17047 19.3462 2.96768 19.4509 2.86075C20.2781 2.00534 21.1413 1.18311 21.9577 0.316644C22.3622 -0.111061 22.662 -0.0999992 23.063 0.316644C23.8757 1.16099 24.7317 1.96109 25.5481 2.80176C25.6781 2.93449 25.7612 3.17784 25.7612 3.36957C25.7612 8.54996 25.7503 13.7267 25.7431 18.907C25.7431 19.1799 25.6817 19.4269 26.1404 19.4232C30.1318 19.3901 34.1231 19.379 38.1108 19.3753C39.2341 19.3753 40.3575 19.3901 41.4808 19.4417C41.7228 19.4527 41.9973 19.5928 42.1852 19.7551C42.6114 20.1238 42.9509 20.6142 43.4097 20.9276C44.2874 21.5286 44.2441 22.3951 44.1826 23.28C44.1718 23.4533 44.0634 23.6524 43.9406 23.7851C43.5216 24.246 43.0376 24.6553 42.6475 25.1419C42.1274 25.7909 41.4844 25.931 40.697 25.9236C35.8532 25.8794 31.0095 25.8757 26.1693 25.8609C26.0393 25.8609 25.9056 25.8831 25.7287 25.9015L25.7323 25.8941Z" />
      </svg>
      {loadingText ? <span className="loading-text">{loadingText}</span> : null}
    </div>
  )
}
