export const DEFAULT_BOOK_SET = 'chardonnay'

export const DEFAULT_BOOK_DIMENSIONS = {
  width: 170,
  height: 230,
  depth: 30,
}

export const DEFAULT_BOOK_COLOR_BG = '#666666'
export const DEFAULT_BOOK_COLOR_TEXT = '#FFFFFF'
