import { Dimensions } from './model'

export type ContentItems<T> = { items: ReadonlyArray<T> }

export type Group = SiteData & {
  books: BooksData
  search_term: string
}
export type GroupedBooks = {
  recentAdditions: Group
  namedroppingRelated: Group
  discover: Array<Filter>
}

export type GroupedBooksData = {
  'recent-additions': Group
  'namedropping-related': Group
  [key: string]: Group
}

export type Bundle = GroupedBooks & {
  books: Array<BookData>
  filters: Categories
}

export type Filter = {
  slug: string
  title: string
}

type Category = {
  tag: string
}

export type SiteData = {
  slug: string
  name: string
  book_count: number
}

export type SitesData = Array<SiteData>

export type Site = {
  slug: string
  name: string
  count: number
}

export type Sites = Array<Site>

export type BoundingBox = [number, number, number, number] | null

export type Categories = Array<Category>

export type BooksData = Array<BookData>
export type BookData = {
  title: string
  author: string
  publisher_name: string
  collection_name: string
  location_name: string
  cover_image: string
  back_image: string
  spine_image: string
  reverse_spine_image: string
  // shelf_image: string
  cover_thumbnail: string
  back_thumbnail: string
  spine_thumbnail: string
  reverse_spine_thumbnail: string
  colour_hash: string
  colours: Array<string>
  short_title: string | null
  spine_text: string
  height_mm: number
  width_mm: number
  thickness_mm: number
  binding_type: BingingType
  can_ocr: boolean
  accession_number: number
  latest_position?: {
    shelf_code: string
    bay_code: string
    bounding_box: BoundingBox
    orientation: Orientation
    is_highlighted: boolean
    shelf_image: string
    created: string
  }
}

// export type BookPosition = {
//   shelf_code: string
//   bay_code: string
//   bounding_box: BoundingBox
//   orientation: 'vertical'
//   shelf_image: string
//   created: string
// }

export type OrientationType = 'vertical' | 'horizontal' | 'forward'
export enum Orientation {
  Vertical = 'vertical',
  Horizontal = 'horizontal',
  Forward = 'forward',
}

export type BingingType = 'paperback' | 'hardback'
export enum Binding {
  Paperback = 'paperback',
  Hardback = 'hardback',
}

export type Book = {
  title: string
  shortTitle: string | null
  author: string
  id: string
  image: string
  spine: string
  back: string
  coverThumbnail: string
  spineThumbnail: string
  backThumbnail: string
  bay: string
  shelf: number
  // position: string
  description: string
  dimensions: Dimensions
  shelfImage: string
  boundingBox: BoundingBox
  binding: BingingType
  isHighlighted: boolean
  orientation: Orientation
  colors: Array<string>
}

export type Books = Array<Book>

type ShelfData = {
  shelf_code: string
  tag: string
  display_name: string
  bay_code: string
  shelf_image: string
}
export type ShelvesData = Array<ShelfData>

export type BayData = {
  bay_code: string
  display_name: string
  shelves: ShelvesData
}

export type BaysData = Array<BayData>

export type Shelf = {
  shelf: number
  tag: string
  displayName: string
  bay: string
  shelfImage: string
}

export type Shelves = Array<Shelf>

export type Bay = {
  bay: string
  displayName: string
  shelves: Shelves
}

export type Bays = Array<Bay>

export type MapMarkers = Array<{
  bay: string
  shelf: number
  coordinates: { x: number; y: number }
  labelAlign: 'left' | 'right'
  rect: [[number, number], [number, number], [number, number], [number, number]]
}>
export type MapData = {
  site: string
  mapPath: string
  maskPath: string
  markers: MapMarkers
  markerType: 'box' | 'point'
  bayCounts: {
    [keyof: string]: number
  }
}

export type MapsData = Array<MapData>
