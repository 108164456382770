import {
  useCallback,
  type ChangeEvent,
  useEffect,
  useState,
  useRef,
} from 'react'

import { useTranslation } from 'react-i18next'

import { Modal, ModalBody } from '../../layouts/Layout/modal'
import { Radio } from '../../components/Radio'
import { Switch } from '../../components/Switch'

import {
  SettingsFooter,
  SettingsContainer,
  DanceSprite,
  TitleBlock,
} from './styles'
import { setBookSet, getBookSet, setAdminMode, getAdminMode } from 'storage'
import getEnv from 'utils/getEnv'
import { Heading, Title } from 'components/Typography'
import { useSites } from 'hooks/useSites'
import { Button } from 'components/Button'

const Settings = (): JSX.Element => {
  const { VITE_APP_VERSION, VITE_APP_EXP_SERVER } = getEnv()
  const { sites } = useSites()
  const [modeChecked, setModeChecked] = useState<boolean>(
    getAdminMode() || false
  )
  const { t } = useTranslation()

  const onBookSetSelect = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target
      setBookSet(value)
    },
    []
  )

  const onModeSelect = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target
    setModeChecked(checked)
    setAdminMode(checked)
  }, [])

  const clearServiceWorkerCache = async () => {
    // Unregister the service worker before clearing caches
    if ('serviceWorker' in navigator) {
      const registrations = await navigator.serviceWorker.getRegistrations()
      registrations.forEach((registration) => {
        console.log('Unregistering service worker', registration)
        registration.unregister()
      })
    }

    if ('caches' in window) {
      const cacheNames = await caches.keys()
      console.log('Clearing caches', cacheNames)
      await Promise.all(cacheNames.map((cacheName) => caches.delete(cacheName)))
    }
  }

  const clearCaches = async () => {
    await clearServiceWorkerCache()
    localStorage.clear()
    window.location.reload()
  }

  const deselectSpotlights = () => {
    fetch(`${VITE_APP_EXP_SERVER}/deselectAll`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        console.log('Deselect all', res)
      })
      .catch((err) => {
        console.log('Deselect All err', err)
      })
  }

  return (
    <SettingsContainer>
      <TitleBlock>
        <Heading size="giantXL">{t('settings.title')}</Heading>
        <DanceSprite />
      </TitleBlock>
      {sites && sites.length > 1 && (
        <div>
          <Title>{t('settings.book-set-label')}</Title>
          <fieldset>
            {sites.map(({ name, count, slug }) => (
              <Radio
                key={slug}
                name="site"
                value={slug}
                onChange={onBookSetSelect}
                checked={getBookSet() === slug}
              >
                {`${name} (${count} Books)`}
              </Radio>
            ))}
          </fieldset>
        </div>
      )}
      <Switch
        key="mode"
        name="mode"
        onChange={onModeSelect}
        checked={modeChecked}
      >
        {t('settings.admin-label')}
      </Switch>

      <SettingsFooter>
        <span>
          {t('settings.version')}: {VITE_APP_VERSION}
          <br />
          {t('settings.admin-label-short')}: {getAdminMode() ? 'ON' : 'OFF'}
          <br />
          {t('settings.admin-label-short')}: {getBookSet()}
        </span>
        <Button small onClick={clearCaches}>
          Clear Caches
        </Button>
        <Button small onClick={() => deselectSpotlights()}>
          Deselect Spotlights
        </Button>
      </SettingsFooter>
    </SettingsContainer>
  )
}

const TAP_TIMEOUT = 300
const TAP_COUNT = 3
const TAP_FINGERS = 3
const TAP_DEBOUNCE = 50
export const SettingsModal = () => {
  const [showSettings, setShowSettings] = useState(false)
  const [tapCount, setTapCount] = useState<number>(0)

  const tapTimeout = useRef<ReturnType<typeof setTimeout> | null>(null)
  const debounceTimeout = useRef<ReturnType<typeof setTimeout> | null>(null)

  const onClose = () => {
    setShowSettings(false)
  }

  useEffect(() => {
    const handleTouchStart = (event: TouchEvent) => {
      if (debounceTimeout.current) clearTimeout(debounceTimeout.current)

      debounceTimeout.current = setTimeout(() => {
        if (event.touches.length === TAP_FINGERS) {
          setTapCount((prevCount) => {
            const count = prevCount + 1
            if (tapTimeout.current) clearTimeout(tapTimeout.current)
            tapTimeout.current = setTimeout(() => {
              setTapCount(0)
            }, TAP_TIMEOUT)
            if (count === TAP_COUNT) {
              setShowSettings(true)
              return 0
            }

            return count
          })
        }
      }, TAP_DEBOUNCE)
    }

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.altKey && event.code === 'KeyD') {
        setShowSettings(true)
      }
    }

    document.addEventListener('touchstart', handleTouchStart)
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      if (tapTimeout.current) clearTimeout(tapTimeout.current)
      if (debounceTimeout.current) clearTimeout(debounceTimeout.current)
      document.removeEventListener('touchstart', handleTouchStart)
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return (
    <Modal show={showSettings} onClose={onClose} closeTitle="Close Settings">
      <ModalBody>
        <Settings />
      </ModalBody>
    </Modal>
  )
}
