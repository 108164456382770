import * as z from 'zod'

/**
 * Typed environment returned by `getEnv`.
 *
 * If you want to know if you're a prod or a dev build, use `import.meta.env`.
 * Otherwise use this rather than the untyped `import.meta.env` so that your
 * environment is correctly validated.
 */
const ENV_SCHEMA = z.object({
  /** This is the app title */
  VITE_APP_TITLE: z.string().default('Mona Library'),

  VITE_APP_DESCRIPTION: z.string(),
  VITE_APP_API_URL: z.string().url(),
  VITE_APP_EXP_SERVER: z.string().url(),

  /** AP Standard Deployment Params */
  VITE_CLIENT: z.string().optional(),
  VITE_PROJECT: z.string().optional(),
  VITE_DEPLOY_ENV: z.string().optional(),

  /** This is the package.json version */
  VITE_APP_VERSION: z.string().optional(),
})

class BadEnvironmentError extends Error {
  name = 'Bad Environment Error'
}

/**
 * Returns the build environment, but only the keys we have validated against
 * the Zod schema {@link ENV_SCHEMA}.
 */
export default (): z.infer<typeof ENV_SCHEMA> => {
  const env = ENV_SCHEMA.safeParse(import.meta.env)

  if (env.success) return env.data

  const message = env.error.errors
    .map((elem) => `${elem.path}: ${elem.message}`)
    .join('\n')

  throw new BadEnvironmentError(message)
}
