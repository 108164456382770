import { createContext, useContext } from 'react'

import { Book, Bundle } from 'types/bundle'

/**
 * For now, we're storing the key details for the app in context and localstorage.
 *
 * If this grows, please break it into smaller contexts so we don't end up in
 * render hell.
 */
export type SelectedAudio = {
  url: string
  title: string
}
type AppContextProps = {
  // Setting up the bundle loader is an expensive operation, so we store the loaded bundle in context for quick parsing.
  bundleLoader: Bundle | null
  // If the content has loaded from the bundle, set this to true.
  contentLoaded: boolean
  // If the content load fails, we want to show a whole-view loading failure.
  loadFailure: boolean
  // The settings panel is stored in all layouts for quick access.
  settingsOpen: boolean
  selectedBook: Book | null
  setSelectedBook: (book: Book | null) => void
  setSettingsOpen: (open: boolean) => void
  textureWorker: Worker
  // socket: WebSocket
}

const emptyWorker = new Worker('', {
  type: 'module',
})

export const AppContext = createContext<AppContextProps>({
  bundleLoader: null,
  contentLoaded: false,
  loadFailure: false,
  settingsOpen: false,
  setSettingsOpen: () => {},
  selectedBook: null,
  setSelectedBook: () => {},
  textureWorker: emptyWorker,
  // socket: new WebSocket(''),
})

export function useAppContext() {
  const context = useContext<AppContextProps>(AppContext)
  if (!context) {
    throw new Error('useAppContext must be used within a AppProvider')
  }
  return context
}
