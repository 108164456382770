import { lazy, useState, useEffect, useLayoutEffect, Suspense } from 'react'

import { createRoot } from 'react-dom/client'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { useTransition, animated } from 'react-spring'
import { ThemeProvider } from 'styled-components'

import BookViewer from 'BookViewer'
import { ErrorFallback } from 'components/ErrorFallback'
import { AppContext } from 'context'
import { useBundle } from 'hooks/useBundle'
import { translations } from 'locales'
import RoutesComponent from 'routes'
import Settings from 'settings'
import { getLanguage, setLanguage } from 'storage'
import { GlobalStyle } from 'styles/global'
import { defaultTheme } from 'styles/theme'
import getEnv from 'utils/getEnv'
import { setupSettings } from 'utils/settings'
import { i18n, useTranslation } from 'utils/translation'
import { LoadingScreen } from 'views/loading'

import { Book, Bundle } from './types/bundle'

// eslint-disable-next-line import/order
import registerServiceWorker from './service-worker-registration'
import { SettingsModal } from 'modals/settings'

const textureWorker = new Worker(
  new URL('./workers/textureWorker.worker', import.meta.url),
  { type: 'module', name: 'TEXTURE' }
)

/**
 * Setup settings universally
 */
setupSettings(Settings)

i18n(translations, Settings.uiLanguages, Settings.defaultUILanguage)

// gaInit()
const BookList = lazy(() => import('./views/book-list'))
const BookDetail = lazy(() => import('./views/book-detail'))

const MLView = lazy(() => import('./views/ml-view'))

const NotFound = lazy(() => import('./views/not-found'))

const env = getEnv()
const routes = [
  {
    path: '/',
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <BookList />
      </Suspense>
    ),
  },
  {
    path: '/book/:bookId',
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <BookDetail />
      </Suspense>
    ),
  },
  {
    path: '/ml',
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <MLView />
      </Suspense>
    ),
  },
]

export const App = () => {
  const {
    i18n: { language },
  } = useTranslation()
  const [reload, setReload] = useState<boolean>(false)
  const [bundleLoader, setBundleLoader] = useState<Bundle | null>(null)
  const [contentLoaded, setContentLoaded] = useState(false)
  const [loadFailure, setLoadFailure] = useState(false)
  const [settingsOpen, setSettingsOpen] = useState(false)
  const [selectedBook, setSelectedBook] = useState<Book | null>(null)

  // const [socket, setSocket] = useState<WebSocket>(new WebSocket(''))

  const { fullBundle } = useBundle()

  // Set Language to `en` if no language is selected`
  if (!getLanguage()) setLanguage('en')

  const transitions = useTransition(!contentLoaded && !loadFailure, {
    enter: { opacity: 1 },
    from: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      duration: 350,
    },
  })

  /**
   *
   * This functionality is in place to take care of vh measurements on iOS safari,
   * where the browser chrome-elements interrupt a typical measurement of 100vh
   */
  useLayoutEffect(() => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    const vh = window.innerHeight
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`)
    window.addEventListener('resize', () => {
      // We execute the same script as before
      const intVh = window.innerHeight
      document.documentElement.style.setProperty('--vh', `${intVh}px`)
    })
  }, [])

  // useEffect(() => {
  //   // Replace with your WebSocket URL
  //   const socketInstance = new WebSocket('ws://192.168.115.127:8080')

  //   // Handle socket connection
  //   socketInstance.onopen = () => {
  //     console.log('WebSocket connection established')
  //   }

  //   // // Handle incoming messages
  //   // socketInstance.onmessage = (event) => {
  //   //   console.log('WebSocket message received:', event.data)
  //   //   setMessages((prevMessages) => [...prevMessages, event.data])
  //   // }

  //   // Handle socket errors
  //   socketInstance.onerror = (error) => {
  //     console.error('WebSocket error:', error)
  //   }

  //   // Handle socket closure
  //   socketInstance.onclose = () => {
  //     console.log('WebSocket connection closed')
  //   }

  //   // Set socket instance to state
  //   setSocket(socketInstance)

  //   // Cleanup function on unmount
  //   return () => {
  //     socketInstance.close()
  //   }
  // }, [])

  useEffect(() => {
    async function setup() {
      try {
        const response = await fullBundle()

        setTimeout(() => {
          setBundleLoader(response)
          textureWorker.postMessage({
            message: 'init',
          })
        }, 1000)
      } catch (e) {
        setLoadFailure(true)
        console.error('Bundle setup failed', e)
      }
    }
    setup()

    const messageHandler = (e: Event) => {
      if (!(e instanceof MessageEvent)) return

      const { message } = e.data
      if (message === 'initialized') {
        setContentLoaded(true)
      }
    }

    textureWorker.addEventListener('message', messageHandler)

    document.addEventListener('book-set-change', () => {
      setReload(true)
    })
  }, [])

  useEffect(() => {
    async function load() {
      setContentLoaded(false)
      setLoadFailure(false)
      setReload(false)
      try {
        const response = await fullBundle()
        setTimeout(() => {
          setBundleLoader(response)
          setContentLoaded(true)
        }, 1000)
      } catch (e) {
        setLoadFailure(true)
        console.error('Reload Bundle setup failed', e)
      }
    }

    if (reload) {
      load()
    }
  }, [reload])

  return (
    <AppContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        bundleLoader,
        loadFailure,
        contentLoaded,
        settingsOpen,
        setSettingsOpen,
        selectedBook,
        setSelectedBook,
        textureWorker,
        // socket,
      }}
    >
      {transitions(
        (props, item) =>
          item && (
            <animated.div className="loading-screen-wrap" style={props}>
              <LoadingScreen />
            </animated.div>
          )
      )}
      <ThemeProvider theme={defaultTheme}>
        <GlobalStyle theme={defaultTheme} />
        <HelmetProvider context={{}}>
          <Helmet
            htmlAttributes={{ lang: language }}
            titleTemplate={`%s - ${env.VITE_APP_TITLE}`}
            defaultTitle={env.VITE_APP_TITLE}
          />
          {loadFailure ? (
            <ErrorFallback />
          ) : (
            <Suspense fallback={<div className="loading-screen" />}>
              <Router>
                <SettingsModal />
                <BookViewer />
                <RoutesComponent routes={routes}>
                  <Route path="*" element={<NotFound />} />
                </RoutesComponent>
              </Router>
            </Suspense>
          )}
        </HelmetProvider>
      </ThemeProvider>
    </AppContext.Provider>
  )
}

const container = document.getElementById('root')

if (import.meta.env.PROD) {
  registerServiceWorker(() => {
    textureWorker.postMessage({ message: 'sw-ready' })
  })
}

if (container) {
  const root = createRoot(container)
  root.render(<App />)
}
