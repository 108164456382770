import styled, { css } from 'styled-components'

import { ThemeFontSize, ThemeFontWeight } from 'styles/theme'
import { font, fontSize, fontWeight, space } from 'styles/utils'

type TitleProps = {
  as?: 'h1' | 'h2'
  size?: Extract<ThemeFontSize, 'l' | 'xxl'>
}

export const Title = styled.h1.attrs({
  className: 'title',
})<TitleProps>`
  line-height: 1.25em;
  font-weight: ${fontWeight('bold')};
  ${({ size = 'l' }) =>
    css`
      font-size: ${fontSize(`${size}`)};
    `}
`

type SubtitleProps = {
  as?: 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'strong'
  size?: Extract<ThemeFontSize, 'base' | 'xs' | 'xl' | 'giant'>
  weight?: Extract<ThemeFontWeight, 'light' | 'bold' | 'base' | 'medium'>
}

export const Subtitle = styled.h3.attrs({
  className: 'subtitle',
})<SubtitleProps>`
  font-weight: ${fontWeight('light')};
  ${({ size = 'base', weight = 'light' }) =>
    css`
      font-size: ${fontSize(`${size}`)};
      font-weight: ${fontWeight(`${weight}`)};
    `}
`

type HeadingProps = {
  as?: 'h2' | 'h3' | 'span'
  size?: Extract<ThemeFontSize, 'billboard' | 'giantXL' | 'giant' | 'xl' | 'm'>
  weight?: Extract<ThemeFontWeight, 'bold' | 'base' | 'medium'>
  pad?: boolean
}

export const Heading = styled.h2.attrs({
  className: 'heading',
})<HeadingProps>`
  font-family: ${font('mona')};
  line-height: 0.9em;
  text-transform: uppercase;
  ${({ size = 'xl', weight = 'bold', pad = false }) =>
    css`
      font-size: ${fontSize(`${size}`)};
      font-weight: ${fontWeight(`${weight}`)};
      padding: ${pad ? `0 ${space(5)}` : '0'};
    `}
`
