import { FC, useEffect, useState, useMemo, ReactPortal } from 'react'

import ReactDOM from 'react-dom'
import { animated, useTransition } from 'react-spring'
import styled, { css } from 'styled-components'

import { A11yText } from 'components/A11yText'
import { FlexGroup } from 'components/FlexGroup'
import { IconButton } from 'components/IconButton'
import { IconClose } from 'components/icons'
import { color, space } from 'styles/utils'

export const Container = styled.div<{ noScroll?: boolean }>`
  --modal-header-height: 84px;
  background: black;
  bottom: 0;
  color: white;
  height: 100%;
  left: 0;
  min-height: var(--vh);
  ${({ noScroll }) =>
    !noScroll &&
    css`
      overflow-y: scroll;
    `}
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  z-index: 200;

  @media only screen and (min-width: 650px) {
    padding-top: var(--header-height);
  }
`

export const Header = styled.header`
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 2;
  > div {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: var(--modal-header-height);
    justify-content: flex-end;
    margin: 0 auto;
    padding: 0 ${space(4)};
    width: 100%;

    > button {
      align-self: flex-start;
      margin-top: ${space(3)};
      color: ${color('background')};
    }
  }
`

type ModalHeaderProps = {
  onClose: () => void
  closeTitle: string
  title?: string
}
export const ModalHeader = ({
  onClose,
  closeTitle,
  title,
}: ModalHeaderProps) => {
  return (
    <Header>
      <div>
        {title && <A11yText as="h1">{title}</A11yText>}
        <IconButton
          title={closeTitle}
          onClick={onClose}
          iconSize="small"
          icon={IconClose}
        />
      </div>
    </Header>
  )
}

export const modalBodyStyles = css`
  margin: var(--modal-header-height) auto ${space(4)};
  max-width: 900px;
  padding: 0 ${space(6)} ${space(24)};

  @media only screen and (min-width: 650px) {
    padding-top: ${space(6)};
  }

  @media only screen and (min-width: 960px) {
    padding-left: ${space(22)};
  }

  &.noHorizontalPadding {
    padding-left: 0;
    padding-right: 0;
  }
`
export const ModalBody = styled(FlexGroup)`
  ${modalBodyStyles}
`

export const Modal: FC<
  ModalHeaderProps & { noScroll?: boolean; show: boolean }
> = ({
  children,
  onClose,
  show,
  closeTitle,
  title,
  noScroll = false,
}): ReactPortal | null => {
  const MODAL_ROOT_ID = 'modal-root'

  const [root, setRoot] = useState<HTMLElement>()

  const instance = useMemo(() => document.createElement('div'), [])

  const createModalRoot = () => {
    const element = document.createElement('div')
    element.setAttribute('id', MODAL_ROOT_ID)
    document.body.appendChild(element)
    setRoot(element)
    return element
  }

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.code === 'Escape') {
      closeModal()
    }
  }

  const openModal = () => {
    document.addEventListener(
      'keydown',
      (event: KeyboardEvent) => handleKeyPress(event),
      false
    )
    if (instance) root?.appendChild(instance)
  }

  const closeModal = () => {
    document.removeEventListener(
      'keydown',
      (event: KeyboardEvent) => handleKeyPress(event),
      false
    )
    document.body.classList.remove('overflow--hidden')
    onClose()
    return instance && (root?.removeChild(instance) as void)
  }

  useEffect(() => {
    // only create the root element if it doesn't already exist.
    setRoot(document.getElementById(MODAL_ROOT_ID) || createModalRoot())
    // cleanup on unmount.
    return closeModal
  }, [])

  useEffect(() => {
    if (show) {
      document.body.classList.add('overflow--hidden')
      openModal()
    }
  }, [show])

  const transitions = useTransition(show, {
    enter: { opacity: 1 },
    from: { opacity: 0 },
    leave: { opacity: 0 },
    config: {
      duration: 350,
    },
    onRest: () => {
      if (!show) closeModal()
    },
  })

  const modalContent = (
    <>
      {transitions(
        (props, item) =>
          item && (
            <animated.div style={props}>
              <Container noScroll={noScroll} role="dialog" title={title}>
                <ModalHeader
                  onClose={() => closeModal()}
                  closeTitle={closeTitle}
                  title={title}
                />
                {children}
              </Container>
            </animated.div>
          )
      )}
    </>
  )

  return ReactDOM.createPortal(modalContent, instance)
}
