import { v4 as uuidv4 } from 'uuid'
import { DEFAULT_BOOK_SET } from '../constants'
const { localStorage } = window

enum Storage {
  basePrefix = 'MONA_LIBRARY',
  language = 'LANGUAGE',
  versionSuffix = 'BUNDLE_VERSION',
  deviceID = 'DEVICE_ID',
  adminMode = 'ADMIN_MODE',
  bookSet = 'BOOK_SET',
}

export const storageDefaults = {
  language: 'en',
  bookSet: DEFAULT_BOOK_SET,
  adminMode: false,
}

export const LanguageChange = new Event('language-change')
export const AdminModeChange = new Event('admin-mode-change')
export const BookSetChange = new Event('book-set-change')

export const setBundleVersion = (version: string): void =>
  localStorage.setItem(
    `${Storage.basePrefix}_${Storage.versionSuffix}`,
    version
  )

export const getBundleVersion = (): string | null =>
  localStorage.getItem(`${Storage.basePrefix}_${Storage.versionSuffix}`)

export const setLanguage = (language: string): void => {
  localStorage.setItem(`${Storage.basePrefix}_${Storage.language}`, language)
  document.dispatchEvent(LanguageChange)
}

export const getLanguage = (): string | null =>
  localStorage.getItem(`${Storage.basePrefix}_${Storage.language}`)

export const setDeviceID = (): void => {
  if (!localStorage.getItem(`${Storage.basePrefix}_${Storage.deviceID}`)) {
    const uuid = uuidv4()
    localStorage.setItem(`${Storage.basePrefix}_${Storage.deviceID}`, uuid)
  }
}
export const getDeviceID = (): string | null =>
  localStorage.getItem(`${Storage.basePrefix}_${Storage.deviceID}`)

export const getAdminMode = (): boolean | null => {
  const adminMode = localStorage.getItem(
    `${Storage.basePrefix}_${Storage.adminMode}`
  )
  return adminMode !== null ? adminMode === 'true' : storageDefaults.adminMode
}
export const setAdminMode = (adminMode: boolean): void => {
  localStorage.setItem(
    `${Storage.basePrefix}_${Storage.adminMode}`,
    JSON.stringify(adminMode)
  )
  document.dispatchEvent(AdminModeChange)
}

export const getBookSet = (): string | null =>
  localStorage.getItem(`${Storage.basePrefix}_${Storage.bookSet}`) ||
  storageDefaults.bookSet

export const setBookSet = (bookSet: string): void => {
  localStorage.setItem(`${Storage.basePrefix}_${Storage.bookSet}`, bookSet)
  document.dispatchEvent(BookSetChange)
}
