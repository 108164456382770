import { ButtonHTMLAttributes, ReactNode } from 'react'

import { IconType } from 'components/icons'
import { IconSizes } from 'components/icons/Icon/types'

import { Button as StyledButton } from './styles'

type IconButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  active?: boolean
  icon: IconType | null
  title: string
  iconSize?: IconSizes
  children?: ReactNode
}

export const IconButton = ({
  icon: Icon,
  iconSize = 'medium',
  children,
  ...rest
}: IconButtonProps) => (
  <StyledButton {...rest}>
    {Icon && <Icon size={iconSize} />}
    <span>{children}</span>
  </StyledButton>
)
