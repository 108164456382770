import styled, { css } from 'styled-components'

import { space, color, font, fontWeight, fontSize } from 'styles/utils'

export const buttonStyles = css`
  align-items: center;
  background-color: ${color('accent')};
  border: 0;
  border-radius: 32px;
  color: ${color('primaryText')};
  display: flex;
  font-family: ${font('base')};
  font-size: ${fontSize('l')};
  font-weight: ${fontWeight('bold')};
  justify-content: center;
  letter-spacing: 0.06em;
  line-height: 1.2;
  min-height: 50px;
  padding: ${space(2)} ${space(8)};
  text-transform: uppercase;
  transition: 280ms all ease-in-out;

  will-change: background-color;

  &[disabled] {
    pointer-events: none;
    opacity: 0.5;
  }

  &.toggled {
    color: ${color('accent')};

    .text:after {
      opacity: 0;
    }
  }
`
export const Button = styled.button<{
  secondary?: boolean
  small?: boolean
}>`
  ${buttonStyles};
  ${({ secondary }) =>
    secondary &&
    css`
      padding: ${space(2)} 0;
      background-color: unset;
      color: ${color('interactive')};
      fill: ${color('interactive')};
      border-radius: 0;
      position: relative;
      text-transform: none;
      .text {
        position: relative;
      }
      .text:after {
        content: '';
        display: block;
        position: absolute;
        top: 30px;
        height: 2px;
        left: 0;
        right: 0;
        background-color: ${color('interactive')};
        width: inherit;
        transition: 280ms all ease-in-out;
      }

      &:hover {
        background-color: unset;
        .text:after {
          height: 4px;
        }
      }
    `}
  ${({ small }) =>
    small &&
    css`
      font-size: ${fontSize('s')};
      min-height: 30px;
      padding: ${space(2)} ${space(4)};
      .text:after {
        height: 1px;
        top: 20px;
      }
      &:hover {
        .text:after {
          height: 3px;
        }
      }
    `}
`
