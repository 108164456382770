// GENERATED BY process-svg DO NOT EDIT
import { GeneratedIcon } from './Icon'
import { GeneratedIconProps } from './Icon/types'
// @ts-ignore
import Svg from './svg/icon-location.svg?react'

export const IconLocation = (props: GeneratedIconProps) => (
  <GeneratedIcon {...props}>
    <Svg preserveAspectRatio="xMidYMid meet" />
  </GeneratedIcon>
)

IconLocation.displayName = 'IconLocation'
