/* eslint-disable no-useless-catch */

import { useAppContext } from 'context'
import {
  Book,
  BookData,
  BooksData,
  // Books,
  // BooksData,
  Bundle,
  Filter,
  GroupedBooks,
  GroupedBooksData,
  Orientation,
  // SiteData,
} from 'types/bundle'
import getEnv from 'utils/getEnv'
import { getBookSet } from 'storage'
/**
 * A wrapper around `fetch` that re-tries if it fails
 *
 *
 * @param url URL to fetch
 * @param retries number of retries to refresh the token
 * @category HTTP
 */
export async function repeatableFetch(
  url: string,
  retries: number = 1
): ReturnType<typeof fetch> {
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
    },
  })

  if (response.status === 401 && retries > 0) {
    // Try again
    return repeatableFetch(url, retries - 1)
  }

  return response
}

export const parseBook = (book: BookData): Book => {
  const {
    title,
    short_title,
    author,
    accession_number,
    cover_image,
    back_image,
    spine_image,
    cover_thumbnail,
    back_thumbnail,
    spine_thumbnail,
    latest_position,
    // categories,
    colours,
    width_mm,
    height_mm,
    thickness_mm,
    binding_type,
  } = book

  return {
    id: `${accession_number}`,
    title,
    shortTitle: short_title,
    author,
    image: cover_image,
    spine: spine_image,
    back: back_image,
    coverThumbnail: cover_thumbnail,
    backThumbnail: back_thumbnail,
    spineThumbnail: spine_thumbnail,
    bay: latest_position?.bay_code || 'N/A',
    shelf: Number(latest_position?.shelf_code) || 0,
    // position: (position?.position && String(position?.position)) || 'N/A',
    dimensions: {
      width: width_mm,
      height: height_mm,
      depth: thickness_mm,
    },
    colors: colours || [],
    orientation: latest_position?.orientation || Orientation.Vertical,
    shelfImage: latest_position?.shelf_image ? latest_position.shelf_image : '',
    boundingBox: latest_position?.bounding_box || null,
    description: '',
    binding: binding_type,
    isHighlighted: false, // position?.is_highlighted || false, // Disabled for now
  }
}
// type Group = SiteData & {
//   books: BooksData
// }
// type GroupedBooks = {
//   recentAdditions: Group
//   namedroppingRelated: Group
//   discover: Array<Filter>
// }

// type GroupedBooksData = {
//   'recent-additions': Group
//   'namedropping-related': Group
//   [key: string]: Group
// }

export const parseGroups = (data: GroupedBooksData): GroupedBooks => {
  const {
    'recent-additions': recentAdditions,
    'namedropping-related': namedroppingRelated,
    ...rest
  } = data

  const discover: Array<Filter> = Object.keys(rest).map((key) => ({
    title: rest[key].name,
    slug: rest[key].search_term,
  }))

  return {
    recentAdditions,
    namedroppingRelated,
    discover,
  }
}

export function useBundleData() {
  const { bundleLoader, contentLoaded } = useAppContext()

  // const listBooks = (): Books | null => {
  //   if (!bundleLoader || !contentLoaded) return null

  //   return bundleLoader.books.map(parseBook)
  // }

  const getBookLists = () => {
    if (!bundleLoader || !contentLoaded)
      return {
        namedroppingRelated: [],
        recentAdditions: [],
      }

    return {
      namedroppingRelated:
        bundleLoader.namedroppingRelated.books.map(parseBook),
      recentAdditions: bundleLoader.recentAdditions.books.map(parseBook),
    }
  }

  const listFilters = () => {
    if (!bundleLoader || !contentLoaded) return null

    return bundleLoader.filters.map(({ tag }) => ({
      slug: tag,
      title: tag,
    }))
  }

  // const getBookById = (id: string) => {
  //   if (!bundleLoader || !contentLoaded) return null
  //   return listBooks()?.find((book) => book.id === id)
  // }

  const getRandomBookId = () => {
    if (!bundleLoader || !contentLoaded) return null
    const index = Math.floor(Math.random() * bundleLoader.books.length)
    if (!bundleLoader.books[index].latest_position) return getRandomBookId()
    return bundleLoader.books[index].accession_number
  }

  const listDiscover = () => {
    if (!bundleLoader || !contentLoaded) return null

    return bundleLoader.discover
  }

  return {
    getBookLists,
    listDiscover,
    // listBooks,
    listFilters,
    // getBookById,
    getRandomBookId,
  }
}

export function useBundle() {
  const env = getEnv()

  /**
   * Wrapper around fetch() that does the following things:
   *
   * - checks the response
   * - updates the state (`data`)
   *
   * @param url URL to fetch
   * @param payload request body
   */
  const fetchAndParse = async <TResponse>(url: string): Promise<TResponse> => {
    try {
      const response = await repeatableFetch(url)

      if (!response.ok) {
        const json = await response.json()
        throw json || { code: response.status, detail: response.statusText }
      }

      const json = await response.json()

      return json
    } catch (exc) {
      throw exc
    }
  }

  const BOOKS = () => `${env.VITE_APP_API_URL}${getBookSet()}/books`
  const GROUPS = () => `${env.VITE_APP_API_URL}${getBookSet()}/groups`

  const fullBundle = async () => {
    if (!env.VITE_APP_API_URL)
      throw new Error('VITE_APP_API_URL missing from .env')

    // const FILTERS = `${env.VITE_APP_API_URL}categories`
    try {
      const data = await Promise.all([
        // fetchAndParse<Categories>(FILTERS),
        fetchAndParse<BooksData>(BOOKS()),
        fetchAndParse<GroupedBooksData>(GROUPS()),
      ]).catch((e) => {
        throw new Error('Cant load books')
      })

      // const [filters, ...rest] = data
      const [books, result] = data
      const groupedBooksData = parseGroups(result)
      // const books: BooksData = [
      //   ...groupedBooksData.recentAdditions.books,
      //   ...groupedBooksData.namedroppingRelated.books,
      // ]

      // const books = groupedBooks.reduce(
      //   (acc: BooksData, curr: BooksData) => [...acc, ...curr],
      //   []
      // )
      const bundle: Bundle = {
        books,
        recentAdditions: groupedBooksData.recentAdditions,
        namedroppingRelated: groupedBooksData.namedroppingRelated,
        discover: groupedBooksData.discover,
        filters: [],
      }
      if (bundle) {
        try {
          return bundle
        } catch (e) {
          throw e
        }
      } else {
        throw new Error('Content is empty')
      }
    } catch (e) {
      throw e
    }
  }

  return {
    fullBundle,
  }
}
